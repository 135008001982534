import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Faq from '../components/Faq/index'

const CommercialAutoLease = ({ title, content, faq }) => (
  <div className="w-full">
    {/* <div className="hidden md:flex w-full">
      <a
        href="#whyDm"
        className="w-1/6 py-4 text-center bg-brand-green flex-grow hover:bg-opacity-90"
      >
        WHY D{'&'}M
      </a>
      <a
        href="#leaseVsBuy"
        className="w-1/6 py-4 text-center bg-brand-green-darker flex-grow hover:bg-opacity-90"
      >
        LEASE VS BUY
      </a>
      <a
        href="#services"
        className="w-1/6 py-4 text-center bg-brand-green flex-grow hover:bg-opacity-90"
      >
        SERVICES
      </a>
      <a
        href="#benefits"
        className="w-1/6 py-4 text-center bg-brand-green-darker flex-grow hover:bg-opacity-90"
      >
        BENEFITS
      </a>
      <a
        href="#partners"
        className="w-1/6 py-4 text-center bg-brand-green flex-grow hover:bg-opacity-90"
      >
        TESTIMONIALS
      </a>
    </div> */}
    <div
      className={'transition-all page-content'}
      dangerouslySetInnerHTML={{ __html: content }}
    />
    {faq?.title && <Faq title={faq?.title} items={faq?.faqs} />}
  </div>
)

const Page = ({ data, pageContext }) => {
  const { wpPage: page } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <Helmet>
        <script type="application/ld+json">
          {`[{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Why Partner with D&amp;M?","acceptedAnswer":{"@type":"Answer","text":"D&amp;M Leasing works with you to create a lease that meets your business needs. Our staff will work with your team directly to ensure your fleet lease is managed with ease and efficiency."}},{"@type":"Question","name":"HOW MUCH CAN I SAVE LEASING?","acceptedAnswer":{"@type":"Answer","text":"Fleet leasing has more advantages than ever before for companies that need the cash flow to reinvest in their business. Get a quote today and find out how much your company could be saving!"}},{"@type":"Question","name":"WHAT MAKES D&amp;M COMMERCIAL LEASING DIFFERENT?","acceptedAnswer":{"@type":"Answer","text":"Spend more time building your business and less time worrying about fleet management. When you lease your commercial fleet from D&amp;M Auto Leasing, we’ll take a care of all your fleet leasing management needs, saving you time and most importantly, money."}},{"@type":"Question","name":"WHY SHOULD I FLEET LEASE?","acceptedAnswer":{"@type":"Answer","text":"Fleet leasing means equipping your staff with the latest and greatest vehicles. With an updated fleet, your employees benefit from improved safety features, better fuel economy and new technologies that will make doing their job a breeze."}}]}]`}
        </script>
      </Helmet>
      <CommercialAutoLease
        title={page.title}
        content={page.content}
        faq={page.faq}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query CommercialAutoLease($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
  }
`
